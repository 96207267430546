export const youtubeLinks = [
  {
    id: 0,
    link: "https://www.youtube.com/embed/videoseries?list=PL-nn9R9ItTZo74IMpZrHohwgyZy0hhle2",
  },
  {
    id: 1,
    link: "https://www.youtube.com/embed/videoseries?list=PL-nn9R9ItTZoX-zM1VG8hmAoCxerkV2on",
  },
  {
    id: 2,
    link: "https://www.youtube.com/embed/videoseries?list=PL-nn9R9ItTZqnhYSsegUYE6dgE0L6Y-se",
  },
  {
    id: 3,
    link: "https://www.youtube.com/embed/videoseries?list=PL-nn9R9ItTZp99m-2yY4OdK8S45JFbDcC",
  },
  {
    id: 4,
    link: "https://www.youtube.com/embed/videoseries?list=PL-nn9R9ItTZpxQir10VxiIfyezZ_UxWo3",
  },
  {
    id: 5,
    link: "https://www.youtube.com/embed/videoseries?list=PL-nn9R9ItTZrFaZtc7ftElOqRu8zHI-7f",
  },
];
